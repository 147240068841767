import type { AppProps } from "next/app";
import Head from "next/head";
import MerakiSDK from "@urbanpiper-engineering/meraki-sdk";


import { Source_Sans_Pro } from "@next/font/google";
import { appWithTranslation } from "next-i18next";

import ThemeProvider from "@urbanpiper-engineering/meraki-components/dist/provider";
import Layout from "../components/layout";

import nextI18nConfig from "../next-i18next.config";
import configSelector from "../external/configSelector";

import ErrorBoundary from "../utils/error";

import "./global.scss";
import pageSelector from "../external/pageSelector";
import { useEffect } from "react";
import { freshChatService, getAnalyticsOptions } from "../utils/analytics";

const fonts = Source_Sans_Pro({
  variable: "--font-family",
  weight: ["400", "600", "700"],
  subsets: ["latin"],
  display: "swap",
  preload: true,
});

function MedusaApp(props: AppProps) {

  const { Component, pageProps } = props;
  const { businessInfo, configJSON, locale, theme } = pageProps;

  if (configJSON) {
    configSelector.updateConfig(configJSON);
    pageSelector.updateConfig(configJSON);

    MerakiSDK.initSync({
      ...configSelector.getSDKConfig(),
      locale,
    }, businessInfo);
  }

  if (theme) {
    theme.fontFamily = fonts.style.fontFamily;
    theme.isStoreClosed = MerakiSDK.getStoreState().isStoreClosed || false;
  }

  useEffect(() => {
    const analyticsInitOptions = getAnalyticsOptions(theme);
    MerakiSDK.analyticsInit(analyticsInitOptions);
    freshChatService();
  }, []);

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link
          rel="icon"
          type="image/x-icon"
          href={configSelector.getFavicon()}
        />
      </Head>
      <ThemeProvider theme={theme}>
        <Layout {...pageProps} className={fonts.className}>
          <ErrorBoundary>
            <Component {...pageProps} />
          </ErrorBoundary>
        </Layout>
      </ThemeProvider>
    </>
  );
}

export default appWithTranslation(MedusaApp, nextI18nConfig);
