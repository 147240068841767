import MerakiSDK from "@urbanpiper-engineering/meraki-sdk";
import configSelector from "../external/configSelector";

export const getMixPanelIds = (
  internalMixPanelIds: string[],
  clientMixPanelIds: string[]
): string[] => {
  const samplingRate = 0.05; // track only 5% of users;

  // Check if mixpanelRandomValue is already present in localStorage
  const storedRandomValue = localStorage.getItem("mixpanelRandomValue");

  let randomValue: number;

  if (storedRandomValue) {
    randomValue = parseFloat(storedRandomValue);
  } else {
    randomValue = Math.random();
    localStorage.setItem("mixpanelRandomValue", randomValue.toString());
  }

  let trackIds = [];

  if (randomValue < samplingRate && internalMixPanelIds?.length > 0) {
    trackIds = [...internalMixPanelIds, ...clientMixPanelIds];
  } else {
    trackIds = [...clientMixPanelIds];
  }
  return trackIds;
};

export const getAnalyticsOptions = (theme: any) => {
  const analyticsConfig = {
    biz_id: configSelector.getBizId(),
    biz_name: configSelector.getBizName(),
    currency: theme?.currency,
  };
  const analyticsInitOptions: any = {
    mixpanelId: [],
    googleAnalyticsId: "",
    googleTagManagerId: "",
    appsflyerId: "",
    config: analyticsConfig,
  };

  if (
    configSelector.getMixPanel()?.enable &&
    configSelector.getMixPanel()?.tokens?.length > 0
  ) {
    analyticsInitOptions.mixpanelId = getMixPanelIds(
      analyticsInitOptions.mixpanelId,
      configSelector.getMixPanel().tokens
    );
  }
  if (configSelector.getGTM()?.enable) {
    analyticsInitOptions.googleTagManagerId = configSelector.getGTM().id;
  }
  if (configSelector.getGA()?.enable) {
    analyticsInitOptions.googleAnalyticsId = configSelector.getGA().id;
  }

  return analyticsInitOptions;
};


export const freshChatService = async () => {
  const freshChat = configSelector.getFreshChat();

  if (freshChat.enable && freshChat.token.length) {
    const freshChatService = await MerakiSDK.getFreshChat();
    freshChatService.init({
      token: freshChat.token,
      backgroundColor: configSelector.getPrimaryColor(),
    });
  }
};
